import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import Link from '../components/Link'
import { Seo } from '../components/Seo'

type NotFoundPageProps = PageProps<Queries.NotFoundPageQuery>

const NotFoundPage = ({
  data: {
    directus: { page },
  },
}: NotFoundPageProps) => {
  return (
    <Layout>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-brand-blue sm:text-5xl">
              404
            </p>
            {page && (
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    {page.title}
                  </h1>
                  <div
                    className="mt-4 prose"
                    dangerouslySetInnerHTML={{
                      __html: page.description || '',
                    }}
                  />
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  {page.home_link && (
                    <Link className="Button text-sm" item={page.home_link} />
                  )}
                  {page.contact_link && (
                    <Link
                      className="Button --inverse text-sm"
                      item={page.contact_link}
                    />
                  )}
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const Head = ({
  data: {
    directus: { page },
  },
}: NotFoundPageProps) => (
  <Seo>
    <title>{page ? `404 - ${page.title}` : '404'}</title>
  </Seo>
)

export const query = graphql`
  query NotFoundPage {
    directus {
      page: page_404 {
        title
        description
        home_link {
          ...DirectusLink
        }
        contact_link {
          ...DirectusLink
        }
      }
    }
  }
`
